'use client'

import { useEffect } from "react";
import Box from "@mui/material/Box";

export default function NotFound() {

  useEffect(() => {
    console.log('not found')
  }, [])

  return (
    <Box sx={{ pl: 3 }}>
      <h2>Not Found</h2>
      <p>Could not find requested resource</p>
    </Box>
  )
}
